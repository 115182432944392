import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/o-nama.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

const Inspiracija = () => (
  <Layout>
    <SEO title="MOONAMAR – INSPIRADO EN LA SABIDURÍA DE LA NATURALEZA" 
    description="MOONAMAR - Selección exclusiva de lo mejor de la naturaleza en forma de suplementos nutricionales y cosméticos."
    />

    <section
      id="_heroPage"
      className="hero is-large _inspiracija"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">INSPIRACIÓN</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <p className="content">
                Cada persona es única en su aspecto, su sistema endocrino, su sistema inmunológico, así como en sus emociones.  Por consiguiente cada persona desarrolla distintas preferencias y reacciona a los  estímulos de su alrededor de diferente modo. Y lo mismo ocurre al elegir la dieta más adecuada, los cosméticos, los complementos alimenticios, etc. Hay que tener en cuenta, que no todos los compuestos hacen el mismo efecto sobre cada metabolismo y que no todos los organismos reaccionan de la misma manera a éstos. Lo que a uno le ayuda, puede no tener el mismo efecto sobre otra persona. al contrario, puede incluso dañarle.
                </p>
                <p className="content">
                Algunas sustancias naturales se puede utilizar de un modo muy general, siendo el tipo de persona que las toma, el que hace que éstas tengan un efecto beneficioso. Estas sustancias naturales tienen que agradecer su uso desde hace miles de años a sus componentes en sus distintas combinaciones, tales como  las proteínas, aminoácidos,  grasas no saturadas,  vitaminas, minerales o antioxidantes. 
                </p>
                <p className="content">
                El equipo de Moonamar se esfuerza en buscar estos regalos que nos presentan  los tesoros de la naturaleza y en ofrecérselos. Nuestro propósito es encontrar y seleccionar aquellos componente que  encuentran un uso tanto en la medicina natural tradicional como en la ciencia moderna. Aún así , depende de cada persona encontrar el  estilo de vida, la alimentación y la preparación de los elementos que encontramos en la naturaleza que mejor se adapten a él y que más le ayuden en su bienestar y en su salud.
                </p>
                <p className="content">
                  <strong>
                  Ponemos a su disposición  un catálogo exclusivo  con productos de alta calidad y complementos alimenticios  con unos efectos beneficiosos para usted. Productos sostenibles y naturales y el bienestar de usted son las metas principales de los expertos con los que contamos en  nuestro equipo. 
                  </strong>
                </p>
                <p className="content">
                Para preguntas, comentarios,  consejos o críticas constructivas se puede poner siempre en contacto con nosotros a través de nuestro formulario o por correo electrónico a:
                  <a href="mailto:support@moonamar.co">support@moonamar.co</a>.
                </p>
                <p className="content">Esperamos que nos escriba</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Inspiracija
